.todo {
	display: flex;
	align-items: center;
	padding: 10px 20px;
	margin: 15px 0;
	font-size: 1.5rem;
	border-radius: 5px;
	border: 2px solid #555;
	color: #112d49;
	background-color: #fbfef9;
}

.todoText {
	width: 100%;
	text-align: left;
}

.todoIcon {
	font-size: 1.8rem;
	margin-right: 10px;
	color: teal;
}

.completedTodo {
	background-color: unset;
	border-color: gray;
	color: gray;
}

.todo.completedTodo .todoIcon,
.todo.completedTodo .checkIcon,
.todo.completedTodo .deleteIcon {
	color: gray;
}

.checkIcon,
.deleteIcon {
	cursor: pointer;
	color: lightgrey;
	padding: 0 7px;
	font-size: 1.8rem;
	transition: transform 0.3s;
}

.checkIcon:hover,
.deleteIcon:hover {
	cursor: pointer;
	transform: scale(1.3);
}

.checkIcon:hover {
	color: green;
}

.deleteIcon:hover {
	color: red;
}
